import { defineStore } from "pinia";
import { ref } from "vue";

export const useGlobalStore = defineStore("global", {
  state: () => {
    return {
      isSidebarMinimized: false,
      userName: "Vasili S",
      userRoles: ref<string | undefined>(),
      loadingController: false,
      leftSideMenuController: "static",
      logoTopBarController: true,
      getTheme: "light",
      currentUserModuleBelongs: "default",
    };
  },
  actions: {
    deleteItem(usuario: any) {
      return usuario === "admin";
    },
    showLoading() {
      this.loadingController = true;
      document.body.style.overflow = "hidden";
    },
    hideLoading() {
      this.loadingController = false;
      document.body.style.overflow = "auto";
    },
    toggleLeftSideMenu(value: string) {
      return (
        (this.leftSideMenuController = value),
        (this.logoTopBarController = value !== "static")
      );
    },
    toggleTheme() {
      if (this.getTheme == "light") {
        return (this.getTheme = "dark");
      } else {
        return (this.getTheme = "light");
      }
    },
    setCurrentUserModuleBelongs(value: string) {
      if (!value) {
        this.currentUserModuleBelongs = "default";
      } else {
        this.currentUserModuleBelongs = value;
      }
    },
  },
});
