import { apolloClient } from "@/main";
import { gql } from "graphql-tag";

export const permissions = {
  action: "updateUsers",
};

export async function getPermissions() {
  const result = await apolloClient.query({
    query: gql`
      {
        __schema {
          mutationType {
            fields {
              name
            }
          }
        }
      }
    `,
  });
  return result;
}
